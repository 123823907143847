<!-- 修改库存 -->

<template>
  <div class="">
    <w-dialog ref="dialogRef" title="修改库存" width="60%" top="15vh" @wConfirm="onConfirmBtn">
      <div>
        <span class="inventory-title">剩余库存：</span>
        <span class="inventory-quantity">{{datas.in_stock}}</span>
      </div>

      <div class="inventory-content">
        <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="修改库存数量" prop="in_stock">
                <el-input type="Number" v-model="ruleForm.in_stock" clearable placeholder="请输入修改库存数量" >
                </el-input>
              </el-form-item>
              <div class="notes">注：输入正数增加库存，负数则为减少库存</div>
            </el-col>

            <el-col :span="8">
              <el-form-item label="成本" prop="goods_cost">
                <el-input v-model="ruleForm.goods_cost" clearable placeholder="请输入成本">
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="入库日期" prop="storage_at">
                <el-date-picker v-model="ruleForm.storage_at" type="date" placeholder="请选择入库日期" format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="16">
              <el-form-item label="上传附件（支持上传JPG、PNG、PDF格式）" prop="files">
                <img-upload type="other" :limit="99" @uploadFile="uploadFile" :fileList="fileList" suggestText=""
                  :uploadTitle="'附件'" :isShowLists="false">
                  <template #fileDatas>
                    <div v-for="(item, i) in fileDatas" :key="i" class="flex">
                      <div class="cursor-pointer text-cut" @click="onPreview(item)">
                        {{item.name}}
                      </div>
                      <div class="cursor-pointer s-m-l" @click="deleteFile(item)">
                        <el-icon>
                          <Close />
                        </el-icon>
                      </div>
                    </div>
                  </template>
                  <template #operate>
                    <el-button type="primary" round @click="openTicketsDialog"
                      v-if="authData.indexOf('n_N8pJWs15zPm7byQMGHelusSbi08') != -1">
                      <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
                      添加门票
                    </el-button>
                  </template></img-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

    </w-dialog>
  </div>
</template>

<script>
  import { ref, reactive, toRefs, onMounted, } from "vue";
  import { StoreApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { checkStr } from "@/utils/common.js";
  import { useStore } from "vuex";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import { Close } from "@element-plus/icons-vue";

  export default {
    name: "modify-inventory",
    components: { imgUpload, Close, },
    emits: ["submit"],
    setup(props, { emit }) {
      const store = useStore();
      // 页面数据
      const state = reactive({
        datas: "",
        fileDatas: [],  // 上传附件数据
        ruleForm: {
          in_stock: '',  // 数量
          goods_cost: '',  // 成本
          storage_at: '',  // 日期
        },
      });
      const formRef = ref(null);  // 填写表单对象
      const fileList = ref([]);  // 上传文件
      /** 表单规则对象 */
      const rules = reactive({
        in_stock: [
          { required: true, message: "请输入库存数量", trigger: "blur", },
        ],
        goods_cost: [
          { required: true, message: "请输入成本", trigger: "blur", },
        ],
        storage_at: [
          {
            required: true, message: "请选择入库日期", trigger: "change",
          },
        ],
      });
      onMounted(() => {

      });
      // 对话框对象
      const dialogRef = ref(null);
      /**
       * 
       * 显示对话框
       * 
       * */
      function openDialog(row) {
        store.dispatch("getQiniuData");
        state.datas = row;
        state.ruleForm.in_stock = '';
        state.ruleForm.goods_cost = '';
        state.ruleForm.storage_at = '';
        fileList.value = [];
        state.fileDatas = [];
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       * */
      function closeDialog() {
        dialogRef.value.close();
      }
      /** 文件上传 回调 */
      const uploadFile = (data) => {
        fileList.value.push({ file_url: data.standard_url, key: data.key, name: data.name });
        state.fileDatas.push({ file_url: data.standard_url, key: data.key, name: data.name });
      };
      /** 文件删除回调 */
      const deleteFile = (data) => {
        let index1 = fileList.value.findIndex((item) => item.key === data.key);
        let index2 = state.fileDatas.findIndex((item) => item === data.key);
        fileList.value.splice(index1, 1);
        state.fileDatas.splice(index2, 1);
      };
      /**
       * 
       * 下载附件
       * 
       * */
      const onPreview = (item) => {
        let link = document.createElement('a');
        let url = store.state.Config.fileUrl + item.key;
        const filename = item.name;
        // 这里是将url转成blob地址，
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob);
          link.download = '';
          document.body.appendChild(link);
          link.download = filename;
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
      }
      /**
       * 
       * 修改库存确认按钮
       * 
       * */
      function onConfirmBtn() {
        formRef.value.validate((valid) => {
          if (valid) {
            if (!checkStr(state.ruleForm.in_stock, "integer")) {
              ElMessage.error("请输入整数！");
              return;
            }
            let params = {
              id: state.datas.id,
              in_stock: state.ruleForm.in_stock,
              goods_cost: state.ruleForm.goods_cost,
              storage_at: state.ruleForm.storage_at,
              files: [],
            };
            if (state.fileDatas.length > 0) {
              state.fileDatas.forEach(item => {
                params.files.push({
                  key: item.key,
                  name: item.name,
                });
              })
            }
            dialogRef.value.isLoading = true;
            StoreApi.setStocks(params).then((res) => {
              if (res.Code === 200) {
                ElMessage.success("操作成功！");
                emit("onConfirm");
              } else {
                let msg = res.Message ? res.Message : "操作失败！";
                ElMessage.error(msg);
              }
              dialogRef.value.isLoading = false;
            });
          }
        })

      }

      return {
        ...toRefs(state),
        openDialog,
        dialogRef,
        closeDialog,
        onConfirmBtn,
        formRef,
        rules,
        fileList,
        uploadFile,
        deleteFile,
        onPreview,
      };
    },
  };
</script>

<style lang="scss">
  .inventory-title {
    font-size: 12px;
    color: var(--text-color);
  }

  .inventory-quantity {
    font-size: 16px;
    color: var(--theme-color);
  }

  .inventory-content {
    margin-top: 20px;
  }

  .content-title {
    margin-bottom: 5px;
    color: var(--text-color);
  }

  .notes {
    margin-bottom: 5px;
    font-size: 10px;
    color: var(--text-third-color);
  }
</style>