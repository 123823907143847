<!-- 绑定收款播报设备 -->

<template>
	<div class="checker-dialog">
		<w-dialog ref="dialogRef" title="收款播报设备配置" width="50%" top="15vh" :hideFooter="true">
			<div class="add-container">
				<!-- 项目标题 -->
				<div class="left-title">{{ tableTitle }}</div>

				<!-- 搜索设备- -->
				<div class="right-search">
					<el-select v-model="equipmeId" placeholder="请输入设备名称查询" filterable remote
						:remote-method="getEquipmeOptions">
						<el-option v-for="item in equipmeOptions" :key="item.id" :label="item.name" :value="item.id">
							<span style="float: left">{{ item.name }}</span>
							<span style="float: left">{{ item.p_name }}</span>
						</el-option>
					</el-select>
					<!-- 添加设备  -->
					<div class="add-btn" @click="onAddDeviceBtn"
						v-if="authData.indexOf('n_5365U7omDJAMnYn9yKuxZvSPL6Ex') != -1">
						添加设备
					</div>
				</div>
			</div>

			<!-- 已绑定收款播报设备列表 -->
			<div class="people-info" v-for="(item,index) in equipInfo" :key="index">
				<div class="basic-info">
					<span>{{ item.name }}</span>
					<span>{{ item.p_name }}</span>
				</div>
				<div class="delete-btn" @click="handleDelete(item)"
					v-if="authData.indexOf('n_DwLY8eImNWg0BNDDK795n9r0lxvi') != -1">
					移除
				</div>
			</div>

			<!-- 缺省页 -->
			<div v-if="equipInfo.length == 0">
				<el-empty description="暂无收款播报设备"></el-empty>
			</div>
		</w-dialog>
	</div>
</template>

<script>
	import { ref, computed, watch } from "vue";
	import { BasicApi, HotelApi, ScenicApi, StoreApi } from "@/plugins/api.js";
	import { ElMessage } from "element-plus";
	import { useStore } from "vuex";

	export default {
		emits: ["submit"],
		components: {},
		setup() {
			// 弹框对象
			const dialogRef = ref(null);
			// 设备选项数据
			const equipmeOptions = ref([]);
			// 设备选中id
			const equipmeId = ref(null);
			// 当前门店数据
			const currentDatas = ref(null);
			// 搜索内容
			const keywords = ref("");
			// 已绑定设备列表
			const equipInfo = ref([]);
			// 标题
			const tableTitle = ref("");
			// 权限
			const authData = ref([]);
			const store = useStore();
			const menuTokens = computed(() => store.state.menuToken.menuTokens);
			// 监听权限
			watch(() => menuTokens.value,
				(data) => {
					if (data.length) {
						authData.value = data;
					}
				},
				{
					deep: true,
					immediate: true,
				}
			);
			/**
			 * 
			 * 显示弹框
			 * 
			 * */
			function openDialog(row) {
				currentDatas.value = row;
				tableTitle.value = row.p_name;
				equipmeId.value = "";
				getEquipLists(2);
				getEquipmeOptions();
				dialogRef.value.show();
			}
			/**
			 * 
			 * 关闭弹框
			 * 
			 * */
			function closeDialog() {
				dialogRef.value.close();
			}
			/**
			 * 
			 * 搜索收款播报设备
			 * 
			 * */
			function getEquipmeOptions(query) {
				keywords.value = query;
				getEquipLists(1);
			}
			/**
			 * 
			 * 获取收款播报设备选项数据
			 * 
			 * */
			function getEquipLists(type) {
				// 1-获取可关联的列表 2-获取当前门店已关联的设备列表
				StoreApi.getIotLists({
					req_type: type,
					keywords: keywords.value,
					pom_id: currentDatas.value.pom_id
				}).then((res) => {
					if (res.Code === 200) {
						if (type == '1') {
							// 可关联的设备列表
							equipmeOptions.value = res.Data || [];
						} else {
							// 已关联的设备列表
							equipInfo.value = res.Data || [];
						}
					} else {
						let msg = res.Message ? res.Message : "获取收款播报设备失败！";
						ElMessage.error(msg);
					}
				});
			}
			/**
			 * 
			 * 添加设备按钮
			 * 
			 * */
			function onAddDeviceBtn() {
				if (!equipmeId.value) {
					ElMessage.error("请选择设备！");
					return false;
				}
				StoreApi.setPolymerizationIot({
					iot_id: equipmeId.value,
					pom_id: currentDatas.value.pom_id
				}).then((res) => {
					if (res.Code === 200) {
						ElMessage.success("添加设备成功！");
						equipmeId.value = "";
						getEquipLists(2);
					} else {
						let msg = res.Message ? res.Message : "添加设备失败！";
						ElMessage.error(msg);
					}
				});
			}
			/**
			 * 
			 * 移除设备按钮
			 * 
			 * */
			function handleDelete(item) {
				StoreApi.removePolymerizationIot({
					iot_id: item.id,
					pom_id: currentDatas.value.pom_id
				}).then((res) => {
					if (res.Code === 200) {
						ElMessage.success("移除设备成功！");
						equipmeId.value = "";
						getEquipLists(2);
					} else {
						let msg = res.Message ? res.Message : "移除设备失败！";
						ElMessage.error(msg);
					}
				});
			}

			return {
				authData,
				dialogRef,
				openDialog,
				closeDialog,
				equipmeOptions,
				getEquipmeOptions,
				equipmeId,
				currentDatas,
				keywords,
				getEquipLists,
				tableTitle,
				equipInfo,
				onAddDeviceBtn,
				handleDelete,
			};
		},
	};
</script>

<style lang="scss">
	.checker-dialog {
		.el-dialog__body {
			height: 400px;
		}

		.add-container {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-bottom: 10px;

			.right-search {
				display: flex;

				.el-select {
					width: 300px;
				}

				.add-btn {
					background-color: var(--btn-bg-color);
					border: 1px solid var(--btn-bg-color);
					color: var(--text-white-color);
					cursor: pointer;
					padding: 0 10px;
					height: 40px;
					line-height: 40px;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;

					&:hover {
						opacity: .8;
					}
				}
			}

			.left-title {
				color: var(--text-color);
			}
		}

		.people-info {
			display: flex;
			justify-content: space-between;
			background-color: var(--theme-bg-color);
			padding: 10px 20px;
			border-radius: 4px;
			margin-top: 10px;

			.basic-info {
				>span {
					padding-right: 24px;
				}
			}

			.delete-action {
				display: flex;

				.operator {
					color: #cccccc;
				}


			}
		}
	}

	.delete-btn {
		padding-left: 24px;
		color: var(--theme-color);
		cursor: pointer;

		&:hover {
			font-weight: bold;
		}
	}
</style>