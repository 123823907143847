<!-- 门店管理--门店列表 -->

<template>
  <div class="vip-container main-cnt">
    <div class="title">门店列表</div>

    <!-- 列表数据 -->
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="StoreApi.getPolymerizations" :columns="tableColumns" @edit="openDialog"
        @vipClick="openCommodity" @statusChange="changePolymerizationStatus" @netConfig="openNetConfig"
        @collection="openCollection">
        <template #operate>
          <el-button type="primary" round @click="openDialog"
            v-if="authData.indexOf('n_5A4933F3F76A921A0742FC3A756E') != -1">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增门店
          </el-button>
        </template>
      </common-table>
    </div>

    <!-- 新增、编辑门店对话框 -->
    <w-dialog ref="dialogRef" class="vip-dialog" :title="ruleForm.pom_id ? '编辑门店' : '新增门店'" width="50%" btnWidth="140px"
      top="20vh" :confirmText="ruleForm.pom_id ? '确认编辑' : '确认新增'" @wConfirm="handleSure">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="所属园区" prop="pom_sid">
              <el-select v-model="ruleForm.pom_sid" clearable placeholder="请选择所属园区">
                <el-option v-for="el in parkOptions" :key="el.s_id" :label="el.s_name" :value="el.s_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="门店分类" prop="pom_type">
              <el-select v-model="ruleForm.pom_type" clearable placeholder="请选择门店分类">
                <el-option :label="item.t_Name" :value="item.pom_type" v-for="item in classification"
                  :key="item.pom_type">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="门店名称" prop="pom_name">
              <el-input v-model.trim="ruleForm.pom_name" clearable placeholder="请输入门店名称" maxlength="10"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="pom_remark" label="门店备注">
              <el-input type="textarea" rows="3" placeholder="请输入详细门店备注" v-model.trim="ruleForm.pom_remark"
                maxlength="30">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>

    <!-- 商品详情 -->
    <ProductDetails ref="onProductRef" @onConfirm="onConfirm"></ProductDetails>

    <!-- 入账配置 -->
    <NetConfig ref="netConfig" @submit="handleNetConfig"></NetConfig>

    <!-- 绑定收款播报设备 -->
    <CollectionDialog ref="collecRef"></CollectionDialog>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, computed, watch } from "vue";
  import { BasicApi, StoreApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import ProductDetails from "../components/product-details.vue";
  import { useStore } from "vuex";
  import NetConfig from "../components/NetConfig.vue";
  import CollectionDialog from "../components/CollectionDialog.vue";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);  // 权限
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  // 表格对象
  const tableRef = ref(null);
  // 表格配置数据
  const tableColumns = ref([
    {
      prop: "pom_id",
      label: "门店ID",
    },
    {
      prop: "pom_name",
      label: "门店名称",
      color: "--text-third-color",
      minWidth: 100,
    },
    {
      prop: "s_name",
      label: "所属园区",
      minWidth: 120,
    },
    {
      type: "block",
      prop: "goods_count",
      label: "在售商品(件)",
      active: "vipClick",
      minWidth: 100,
      token: "n_F685E0282501F26AB304BCC403B2",
    },
    {
      prop: "pom_status",
      type: "switch",
      label: "门店状态",
      token: "n_A67BF76E0682290D073CFF87AB9F",
    },
    {
      prop: "pom_remark",
      label: "门店备注",
      minWidth: 200,
      color: "--text-third-color",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 280,
      bottons: [
        {
          name: "入账配置",
          action: "netConfig",
          token: "n_pxtXOBFsAzdI3fyylbiBy31W9sQQ",
          className: "black-font-btn",
        },
        {
          name: "编辑",
          action: "edit",
          token: "n_5FE3D93896D1F1960223074151A4",
        },
        {
          name: "收款播报",
          action: "collection",
          token: "n_SUVcIQiT2eq8fRA2dUfadjCsDBpH",
        },
      ],
    },
  ]);
  // 筛选配置
  const filters = ref([
    {
      filterType: "select",
      name: "pom_sid",
      value: "",
      placeholder: "请选择园区",
      options: [],
      val: "s_id",
      lab: "s_name",
    },
    {
      filterType: "select",
      name: "pom_type",
      value: "",
      placeholder: "请选择门店分类",
      options: [{ pom_type: "1", t_Name: "线上" }, { pom_type: "2", t_Name: "线下" }],
      val: "pom_type",
      lab: "t_Name",
    },
    {
      filterType: "select",
      name: "pom_status",
      value: "",
      placeholder: "请选择门店状态",
      options: [{ pom_status: "1", s_name: "正常" }, { pom_status: "2", s_name: "禁用" }],
      val: "pom_status",
      lab: "s_name",
    },
    {
      filterType: "search",
      name: "keywords",
      value: "",
      placeholder: "请输入门店关键词或ID进行搜索",
    },
  ]);
  // 新增、编辑填写内容
  const ruleForm = ref({
    pom_sid: '',  // 园区
    pom_type: "",  // 分类
    pom_name: "",  // 名称
    pom_remark: "",  // 备注
  });
  // 验证内容不为空
  const rules = reactive({
    pom_sid: [{ required: true, message: "请选择所属园区", trigger: "chaneg" }],
    pom_type: [{ required: true, message: "请选择门店所属分类", trigger: "chaneg" }],
    pom_name: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
  });

  // 分类下拉选项
  const classification = ref([{ pom_type: "1", t_Name: "线上" }, { pom_type: "2", t_Name: "线下" }]);

  onMounted(() => {
    getParkDatas();
    tableRef.value.tableLoad();
  });

  const parkOptions = ref([]);  // 园区选项
  /**
  *
  * 获取园区选项
  *
  * */
  const getParkDatas = () => {
    BasicApi.getScenicByProject({}).then((res) => {
      if (res.Code === 200) {
        parkOptions.value = res.Data;
        filters.value[0].options = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取优惠券类型失败！";
        ElMessage.error(msg);
      }
    });
  }
  // 新增、编辑对话框对象
  const dialogRef = ref(null);
  // 表单对象
  const formRef = ref(null);
  /**
   * 
   * 新增、编辑门店
   * 
   * */
  const openDialog = (row) => {
    if (row.pom_id) {
      //编辑
      const data = JSON.parse(JSON.stringify(row));
      data.pom_type = String(data.pom_type);
      ruleForm.value = data;
    } else {
      // 新增
      ruleForm.value = {
        pom_sid: '',
        pom_type: "",  // 分类
        pom_name: "",  // 名称
        pom_remark: "",  // 备注
      };
    }
    dialogRef.value.show();
  };
  /**
   * 
   * 新增/编辑 确认提交按钮
   * 
   * */
  const handleSure = () => {
    formRef.value.validate((valid) => {
      if (valid) {
        dialogRef.value.isLoading = true;
        const data = JSON.parse(JSON.stringify(ruleForm.value));
        let url = "";
        if (data.pom_id) {
          // 编辑
          url = "editPolymeriza";
        } else {
          // 新增
          url = "addPolymeriza";
        }
        // console.log("data", data, url);
        StoreApi[url](data).then((res) => {
          dialogRef.value.isLoading = false;
          const text = data.pom_id ? "修改" : "新增";
          if (res.Code === 200) {
            ElMessage.success(`门店${text}成功！`);
            dialogRef.value.close();
            tableRef.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : `门店${text}失败！`;
            ElMessage.error(msg);
          }
        });
      }
    });
  };
  /**
   * 
   * 门店状态事件
   * 
   * */
  function changePolymerizationStatus(row) {
    if (row.pom_mpid == 0) {
      ElMessage.error("请先设置入账配置！");
      return false;
    }
    let data = {
      pom_id: row.pom_id,
      pom_status: row.pom_status == 1 ? 2 : 1,  // 1正常 2禁用
    };
    StoreApi.updatePolymerizaStatus(data).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("操作成功！");
        tableRef.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "操作失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
   * 
   * 点击在售商品查看商品详情
   * 
   * */
  const onProductRef = ref(null);
  const openCommodity = (row) => {
    onProductRef.value.openDialog(row);
  };
  /**
   * 
   * 修改库存后重新获取商品详情
   * 
   * */
  const onConfirm = () => {
    tableRef.value.tableLoad();
  }
  // 入账配置对象
  const netConfig = ref(null);
  /**
   * 
   * 打开入账配置对话框
   * 
   * */
  function openNetConfig(row) {
    netConfig.value.openDialog(row);
  }
  /**
   * 
   * 入账配置确定按钮
   * 
   * */
  function handleNetConfig(data) {
    StoreApi.setPolymerizaDivide(data).then((res) => {
      netConfig.value.closeDialogLoading();
      if (res.Code === 200) {
        ElMessage.success(`操作成功！`);
        netConfig.value.closeDialog();
        // 重新获取列表数据
        tableRef.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : `操作失败！`;
        ElMessage.error(msg);
      }
    });
  }

  const collecRef = ref(null);
  /**
   * 
   * 绑定收款播报设备
   * 
   * */
  function openCollection(row) {
    collecRef.value.openDialog(row);
  }
</script>
<style lang="scss">
  .vip-container {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;
    }

    .vip-dialog {
      .el-dialog {
        min-width: 600px;

        .el-dialog__body {
          padding: 30px;

          .el-divider--horizontal {
            margin: 10px 0;
          }
        }
      }
    }
  }
</style>