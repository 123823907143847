<!-- 入账配置 -->

<template>
  <div class="net-config-dialog">
    <w-dialog ref="dialogRef" title="入账配置" width="60%" top="4vh" @wConfirm="handleSure">
      <div class="add-container">
        <div class="ticket-title">{{ sendData.pom_name }}</div>
      </div>

      <el-divider></el-divider>

      <div class="net-config-content">
        <el-form ref="formRef" label-position="top">
          <el-row :gutter="20" class="net-config-row">
            <el-col :span="8">
              <el-form-item>
                <el-select v-model="configureForm.pom_mpid" placeholder="请选择入账账号" clearable>
                  <el-option v-for="(item, index) in accountOptions" :label="item.mp_mch_name" :value="item.mp_id"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <el-input v-model="configureForm.pom_percent" autocomplete="off" placeholder="请输入分账比例" readonly>
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, reactive, toRefs } from "vue";
  import { BasicApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  export default {
    emits: ["submit"],
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 对话框对象
      const formRef = ref(null);  // 填写表单对象
      const state = reactive({
        title: "",  // 标题
        sendData: "",   // 当前打开数据
        accountOptions: [],  // 入账账号选项数据
        configureForm: {  // 表单数据
          pom_id: "",
          pom_mpid: "",
          pom_percent: 100
        }
      });
      /**
       * 
       * 显示对话框
       * 
       **/
      function openDialog(sendData) {
        state.sendData = sendData;
        state.configureForm.pom_id = sendData.pom_id;
        // 是否配置
        state.configureForm.pom_mpid = sendData.pom_mpid == 1 ? sendData.pom_mpid : "";
        getAccountOptions();
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       **/
      function closeDialog() {
        dialogRef.value.close();
      }
      /**
       * 
       * 获取商户号选项
       * 
       **/
      function getAccountOptions() {
        BasicApi.accountOptions().then((res) => {
          if (res.Code === 200) {
            state.accountOptions = res.Data || [];
          } else {
            let msg = res.Message || "获取商户号支付配置失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 按钮加载状态
       * 
       **/
      function closeDialogLoading() {
        dialogRef.value.isLoading = false;
      }
      /**
       * 
       * 对话框确定按钮
       * 
       **/
      function handleSure() {
        const data = JSON.parse(JSON.stringify(state.configureForm));
        if (!data.pom_mpid) {
          ElMessage.warning("请将所填信息补充完整！");
          return false;
        }
        dialogRef.value.isLoading = true;
        emit("submit", data);
      }

      return {
        ...toRefs(state),
        formRef,
        handleSure,
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
      };
    },
  };
</script>

<style lang="scss">
  .net-config-dialog {
    .el-dialog__body {
      .net-config-content {
        height: 380px;
        overflow-y: auto;
      }
    }

    .el-divider--horizontal {
      border-top: 1px #eee solid;
    }

    .action-btn {
      color: var(--theme-color);
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }

    .add-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ticket-title {
        font-size: 16px;
        color: var(--text-color);
        font-weight: bold;
      }
    }

    .net-config-row {
      border: 2px dashed #eee;
      margin: 0 0 16px 0 !important;
      padding: 16px 10px;

      .el-form-item {
        margin-bottom: 0;

        .el-input-group__append {
          background-color: var(--search-uncheck-bg-color);
          border: none;
        }
      }

      .delete-btn {
        text-align: right;
        height: 40px;
        line-height: 40px;
      }
    }
  }
</style>